import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { RolebotClient } from 'api/client';
import { AxiosError, AxiosRequestConfig } from 'axios';
import {
  ChangePasswordPayload,
  ConnectGreenhousePayload,
  ConnectOktaPayload,
  CreateGreenhouseRolePayload,
  ConnectSapPayload,
  CronofyConferencingSettingsPayload,
  CronofyUserCalendarMutationPayload,
  DeleteRoleUserPayload,
  InviteUserPayload,
  LinkGreenhouseJobPayload,
  LinkLeverJobPayload,
  LoginPayload,
  PatchTalentParams,
  PatchUserPayload,
  PostRoleUserPayload,
  ResetPasswordPayload,
  RoleParams,
  SignUpPayload,
  UpdatePasswordPayload,
  LinkSapJobPayload,
  ConnectICIMSPayload,
  UpdateICIMSPortalsPayload,
  ICIMSPortal,
  ICIMSRegion,
  ICIMS,
  ICIMSResponse,
  LinkICIMSJobPayload,
} from 'types/requests';
import {
  CompanyUsersResponse,
  CronofyCalendarsResponse,
  CronofyConferencingSettingsResponse,
  GreenhouseRolesResponse,
  ListResponse,
  LoginResponse,
  OutcomesResponse,
  PaginatedResponse,
  UserResponse,
} from 'types/responses';
import { ICustomer, IManagedUser, IRole, ITalent } from 'types';
import { getTalentStatus } from 'utils';

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await RolebotClient({ baseURL: process.env.REACT_APP_API_URL, url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const rolebotApi = createApi({
  reducerPath: 'rolebotApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Roles',
    'Talents',
    'Customers',
    'Greenhouse',
    'Lever',
    'User',
    'Stripe',
    'Users',
    'CronofyUser',
    'Cronofy',
    'Modals',
    'Sap',
    'ICIMS',
    'Locations',
    'RoleReviewedBy',
    'Messages',
    'AISkills',
    'Role',
    'Feedback',
    'TalentActivity',
    'ATSRoles',
    'PricingPlans'
  ],
  endpoints: (builder) => ({
    csrf: builder.query<void, void>({
      query: () => ({ url: '/sanctum/csrf-cookie', method: 'get' }),
    }),
    signUp: builder.mutation<any, SignUpPayload>({
      query: (payload) => ({ url: '/register', method: 'post', data: payload }),
    }),
    signIn: builder.mutation<LoginResponse, LoginPayload>({
      query: (data) => ({ url: '/login', method: 'post', data }),
      invalidatesTags: (result) => (result ? ['User'] : []),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({ url: '/logout', method: 'post' }),
      invalidatesTags: ['Roles', 'Talents', 'Customers', 'Greenhouse', 'Lever', 'User', 'Modals'],
    }),
    getUser: builder.query<UserResponse, void>({
      query: () => ({ url: '/me', method: 'get' }),
      keepUnusedDataFor: 1800000,
      providesTags: (result) => (result ? ['User'] : []),
    }),
    getUsers: builder.query<any, any>({
      query: ({per_page = 10, page = 1, params={}}) => ({ url: '/users', method: 'get', params: {
        ['per-page']: per_page,
        page,
        ...params
      } }),
      providesTags: (result) => (result ? ['Users'] : []),
    }),
    reactivateUser: builder.mutation<any, any>({
      query: (userId) => ({ url: `/users/${userId}/reactivate`, method: 'post' }),
      invalidatesTags: (result) => (result ? ['Users'] : []),
    }),
    patchCompanyUser: builder.mutation<
      void,
      { userId: number; payload: { is_admin?: boolean; show_welcome_modal?: boolean } }
    >({
      query: ({ userId, payload }) => ({ url: `/users/${userId}`, method: 'patch', data: payload }),
      invalidatesTags: (result) => (result ? ['Users'] : []),
    }),
    patchUser: builder.mutation<any, PatchUserPayload>({
      query: (payload) => ({ url: '/user/profile', method: 'patch', data: payload }),
      invalidatesTags: (result) => (result ? ['User'] : []),
    }),
    getCompany: builder.query<ICustomer, void>({
      query: () => ({ url: '/customers', method: 'get' }),
      providesTags: ['Customers'],
    }),
    getCompanyInformation: builder.query<any, string | number>({
      query: (companyId) => ({ url: `/customers/get-information?company_id=${companyId}`, method: 'get' }),
      providesTags: ['Customers'],
    }),
    getCompanyIntegrations: builder.query<any, string | number>({
      query: (customerId) => ({ url: `/customers/get-integrations?customer_id=${customerId}`, method: 'get' }),
      providesTags: ['Customers'],
    }),
    getCompanyUsers: builder.query<any, void>({
      query: () => ({ url: `/users`, method: 'get' }),
      providesTags: ['Customers'],
      transformResponse: (result: CompanyUsersResponse, meta, arg) => {
        return result?.users
          ? result.users.map((u: IManagedUser) => ({
              ...u,
              status: Boolean(u.invitation) ? 'Active' : 'Pending',
            }))
          : [];
      },
    }),
    createRole: builder.mutation<any, any>({
      query: (data) => ({ url: '/request-role', method: 'post', data }),
      invalidatesTags: ['Roles', 'Customers'],
    }),
    pauseRole: builder.mutation<any, any>({
      query: ({ roleId, payload }) => ({
        url: `/roles/${roleId}`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: [{ type: 'Roles', id: 'PARTIAL-LIST' }, 'Role'],
    }),
    resumeRole: builder.mutation<any, any>({
      query: ({ roleId, payload }) => ({
        url: `/roles/${roleId}`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: [{ type: 'Roles', id: 'PARTIAL-LIST' }, 'Role'],
    }),
    roleUsers: builder.mutation<any, any>({
      query: ({ roleId, payload }) => ({
        url: `/roles/${roleId}/user`,
        method: 'post',
        data: { email: { payload } },
      }),
      invalidatesTags: (result, error, arg) => (result ? [{ type: 'Roles' as const, id: arg.roleId }, 'Users'] : []),
    }),
    closeRole: builder.mutation<any, { roleId: string | number; payload: any; reload?: boolean }>({
      query: ({ roleId, payload }) => ({
        url: `/roles/${roleId}/close`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        if (arg.reload) {
          return result ? ['Roles', 'Customers'] : [];
        } else {
          return result ? [{ type: 'Roles' as const, id: arg.roleId }, 'Role'] : [];
        }
      },
    }),
    openRole: builder.mutation<any, { roleId: string | number; payload: any; reload?: boolean }>({
      query: ({ roleId, payload }) => ({
        url: `/roles/${roleId}/open`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        if (arg.reload) {
          return result ? ['Roles', 'Customers'] : [];
        } else {
          return result ? [{ type: 'Roles' as const, id: arg.roleId }, 'Role'] : [];
        }
      },
    }),
    getRoles: builder.query<ListResponse<IRole>, RoleParams>({
      query: ({ type, page = 1, search = '', per_page = 10, ...rest }) => ({
        url: '/roles',
        method: 'get',
        params: {
          'role-filter-type': type,
          search,
          page,
          per_page,
          ...rest,
        },
      }),
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'Roles' as const, id })), { type: 'Roles', id: 'PARTIAL-LIST' }]
          : [{ type: 'Roles', id: 'PARTIAL-LIST' }],
    }),
    getRoleMetrics: builder.query({
      query: ({ per_page = 5000 }) => ({
        url: '/role-metrics?include-ats=1',
        method: 'get',
        params: {
          ['per-page']: per_page,
        },
      }),
    }),
    getRoleLastReviewedBy: builder.query({
      query: (roleId) => ({ url: `/roles/${roleId}/last-reviewed`, method: 'get'}),
      providesTags: ['RoleReviewedBy'],
    }),
    getRoleById: builder.query<IRole, string | number>({
      query: (roleId) => ({ url: `/roles/${roleId}`, method: 'get' }),
      providesTags: (result) => (result ? [{ type: 'Role' as const, id: result.id }] : []),
      keepUnusedDataFor: 1800000,
    }),
    getRoleTalent: builder.query<PaginatedResponse<ITalent>, string>({
      query: (roleId) => ({ url: `/roles/${roleId}/talent`, method: 'get' }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Talents' as const, id })),
              {
                type: 'Talents',
                id: 'PARTIAL-LIST',
              },
            ]
          : [{ type: 'Talents', id: 'PARTIAL-LIST' }],
      transformResponse: (response: PaginatedResponse<ITalent>) => {
        const newResponse = { ...response };
        newResponse.data.forEach((talent) => {
          talent.type = getTalentStatus(talent);
        });
        return newResponse;
      },
      keepUnusedDataFor: 1800000,
    }),
    getRoleTalentPaginated: builder.query<PaginatedResponse<ITalent>, any>({
      query: ({ roleId, type, page = 1, per_page = 15 }) => ({
        url: `/roles/${roleId}/talent`,
        method: 'get',
        params: {
          'talent-filter-type': type,
          page,
          per_page,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Talents' as const, id })),
              {
                type: 'Talents',
                id: 'PARTIAL-LIST',
              },
            ]
          : [{ type: 'Talents', id: 'PARTIAL-LIST' }],
      transformResponse: (response: PaginatedResponse<ITalent>) => {
        const newResponse = { ...response };
        newResponse.data.forEach((talent) => {
          talent.type = getTalentStatus(talent);
        });
        return newResponse;
      },
      keepUnusedDataFor: 1800000,
    }),
    patchTalent: builder.mutation<unknown, PatchTalentParams>({
      query: ({ roleId, talentId, payload }) => ({
        url: `/roles/${roleId}/talent/${talentId}`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: (result: any) =>
        result
          ? [
              { type: 'Talents', id: 'PARTIAL-LIST' },
              { type: 'Roles'},
              { type: 'RoleReviewedBy', id: result.id },
              { type: 'TalentActivity', id: result.id}
            ]
          : [],
    }),
    postRoleInfo: builder.mutation<IRole, any>({
      query: ({ roleId, payload }) => ({ url: `/roles/${roleId}/info`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? [{ type: 'Roles', id: result.id }] : []),
    }),
    patchRoleInfo: builder.mutation<IRole, any>({
      query: ({ roleId, payload }) => ({ url: `/roles/${roleId}/info`, method: 'patch', data: payload }),
      invalidatesTags: (result) => (result ? [{ type: 'Roles', id: result.id }, 'Role'] : []),
    }),
    postRoleUser: builder.mutation<any, PostRoleUserPayload>({
      query: ({ roleId, payload }) => ({ url: `/roles/${roleId}/user`, method: 'post', data: payload }),
      invalidatesTags: (result, error, arg) => (result ? [{ type: 'Roles' as const, id: arg.roleId }, 'Users', 'Role'] : []),
    }),
    postContactSupport: builder.mutation({
      query: (payload) => ({ url: `/support`, method: 'post', data: payload }),
    }),
    deleteRoleUser: builder.mutation<any, DeleteRoleUserPayload>({
      query: ({ roleId, payload }) => ({ url: `/roles/${roleId}/user`, method: 'delete', data: payload }),
      invalidatesTags: (result, error, arg) => (result ? [{ type: 'Roles' as const, id: arg.roleId }, 'Role'] : []),
    }),
    connectGreenhouse: builder.mutation<void, ConnectGreenhousePayload>({
      query: (payload) => ({ url: `/greenhouse`, method: 'post', data: payload }),
      invalidatesTags: ['Customers'],
    }),
    deleteGreenhouse: builder.mutation<void, void>({
      query: () => ({ url: `/greenhouse`, method: 'delete' }),
      invalidatesTags: ['Customers'],
    }),
    getRoleStats: builder.query<any, any>({
      query: (payload) => ({ url: `/roles/${payload.roleId}/talent-stats`, method: 'get' }),
      providesTags: (result) => (result ? [{ type: 'Roles' as const, id: result.id }] : []),
    }),
    getGreenhouseRoles: builder.query<GreenhouseRolesResponse, any>({
      query: (payload = { type: 'name', value: '' }) => ({
        url: `/greenhouse-jobs`,
        method: 'get',
        params: {
          [`${payload.type}`]: payload.value,
          ['external']: 1,
        },
      }),
      providesTags: ['Greenhouse'],
    }),
    createRoleFromGreenhouse: builder.mutation<any, CreateGreenhouseRolePayload>({
      query: (payload) => ({ url: `/greenhouse-roles`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Greenhouse', 'Roles', 'Customers'] : []),
    }),
    linkGreenhouseJob: builder.mutation<any, LinkGreenhouseJobPayload>({
      query: ({ roleId, greenhouse_jobId }) => ({
        url: `roles/${roleId}?greenhouse_job_id=${greenhouse_jobId}`,
        method: 'patch',
      }),
      invalidatesTags: (result) => (result ? ['Greenhouse', 'Roles', 'ATSRoles'] : []),
    }),
    unlinkGreenhouseJob: builder.mutation<any, number>({
      query: (roleId) => ({ url: `roles/${roleId}/greenhouse-job`, method: 'delete' }),
      invalidatesTags: (result) => (result ? ['Greenhouse', 'Roles', 'ATSRoles'] : []),
    }),
    connectOkta: builder.mutation<void, ConnectOktaPayload>({
      query: (payload) => ({ url: `/okta`, method: 'post', data: payload }),
      invalidatesTags: ['Customers'],
    }),
    disconnectOkta: builder.mutation<void, void>({
      query: () => ({ url: `/okta`, method: 'delete' }),
      invalidatesTags: ['Customers'],
    }),
    connectLever: builder.mutation<void, void>({
      query: () => ({ url: `/lever`, method: 'post' }),
      invalidatesTags: ['Customers'],
    }),
    disconnectLever: builder.mutation<void, void>({
      query: () => ({ url: `/lever`, method: 'delete' }),
      invalidatesTags: ['Customers'],
    }),
    connectSap: builder.mutation<void, ConnectSapPayload>({
      query: (payload) => ({ url: `/sap`, method: 'post', data: payload }),
      invalidatesTags: ['Customers'],
    }),
    disconnectSap: builder.mutation<void, void>({
      query: () => ({ url: `/sap`, method: 'delete' }),
      invalidatesTags: ['Customers'],
    }),
    getSapRoles: builder.query<any, any>({
      query: (payload = { type: 'name', value: '' }) => ({
        url: `/sap-jobs`,
        method: 'get',
        params: {
          [`${payload.type}`]: payload.value,
        },
      }),
      providesTags: ['Sap'],
    }),
    createRoleFromSap: builder.mutation<any, any>({
      query: (payload) => ({ url: `/sap-roles`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Sap', 'Roles', 'Customers'] : []),
    }),
    linkSapJob: builder.mutation<any, LinkSapJobPayload>({
      query: ({ roleId, payload }) => ({
        url: `roles/sap/${roleId}`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: (result) => (result ? ['Sap', 'Roles', 'ATSRoles'] : []),
    }),
    unlinkSapJob: builder.mutation<any, number>({
      query: (roleId) => ({ url: `roles/${roleId}/sap-job`, method: 'delete' }),
      invalidatesTags: (result) => (result ? ['Sap', 'Roles', 'ATSRoles'] : []),
    }),
    getICIMS: builder.query<ICIMS, void>({
      query: () => ({
        url: `/icims`,
        method: 'get',
      }),
      providesTags: ['ICIMS'],
      transformResponse: (result: ICIMSResponse, meta, arg) => {
        return {
          customerId: result.icims_id ?? '',
          region: result.region ?? '',
        };
      },
    }),
    connectICIMS: builder.mutation<void, ConnectICIMSPayload>({
      query: (payload) => ({ url: `/icims`, method: 'post', data: payload }),
      invalidatesTags: ['Customers', 'ICIMS'],
    }),
    getICIMSPortals: builder.query<ICIMSPortal[], void>({
      query: () => ({
        url: `/icims/portals`,
        method: 'get',
      }),
      providesTags: ['ICIMS'],
    }),
    getICIMSRegions: builder.query<ICIMSRegion[], void>({
      query: () => ({
        url: `/icims/regions`,
        method: 'get',
      }),
      providesTags: ['ICIMS'],
    }),
    updateICIMSPortals: builder.mutation<void, UpdateICIMSPortalsPayload>({
      query: (payload) => ({ url: `/icims/portals`, method: 'patch', data: payload }),
      invalidatesTags: ['Customers', 'ICIMS'],
    }),
    disconnectICIMS: builder.mutation<void, void>({
      query: () => ({ url: `/icims`, method: 'delete' }),
      invalidatesTags: ['Customers', 'ICIMS'],
    }),
    createRoleFromICIMS: builder.mutation<any, any>({
      query: (payload) => ({ url: `/icims-roles`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['ICIMS', 'Roles', 'Customers'] : []),
    }),
    linkICIMSJob: builder.mutation<any, LinkICIMSJobPayload>({
      query: ({ roleId, payload }) => ({
        url: `roles/icims/${roleId}`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: (result) => (result ? ['ICIMS', 'Roles', 'ATSRoles'] : []),
    }),
    unlinkICIMSJob: builder.mutation<any, number>({
      query: (roleId) => ({ url: `roles/${roleId}/icims-job`, method: 'delete' }),
      invalidatesTags: (result) => (result ? ['ICIMS', 'Roles', 'ATSRoles'] : []),
    }),
    getICIMSRoles: builder.query<any, any>({
      query: (payload = { type: 'name', value: '' }) => ({
        url: `/icims-jobs`,
        method: 'get',
        params: {
          [`${payload.type}`]: payload.value,
        },
      }),
      providesTags: ['ICIMS'],
    }),
    getLeverRoles: builder.query<any, any>({
      query: (payload = { type: 'name', value: '' }) => ({
        url: `/lever-jobs`,
        method: 'get',
        params: {
          [`${payload.type}`]: payload.value,
          ['state']: payload.state,
          ['distributionChannel']: 'public',
        },
      }),
      providesTags: ['Lever'],
    }),
    createRoleFromLever: builder.mutation<any, any>({
      query: (payload) => ({ url: `/lever-roles`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Lever', 'Roles', 'Customers'] : []),
    }),
    linkLeverJob: builder.mutation<any, LinkLeverJobPayload>({
      query: ({ roleId, payload }) => ({
        url: `roles/lever/${roleId}`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: (result) => (result ? ['Lever', 'Roles', 'ATSRoles'] : []),
    }),
    unlinkLeverJob: builder.mutation<any, number>({
      query: (roleId) => ({ url: `roles/${roleId}/lever-job`, method: 'delete' }),
      invalidatesTags: (result) => (result ? ['Lever', 'Roles', 'ATSRoles'] : []),
    }),
    resetPassword: builder.mutation<void, ResetPasswordPayload>({
      query: (payload) => ({ url: `/password/email`, method: 'post', data: payload }),
    }),
    changePassword: builder.mutation<void, ChangePasswordPayload>({
      query: (payload) => ({ url: `/password/reset`, method: 'post', data: payload }),
    }),
    updatePassword: builder.mutation<void, UpdatePasswordPayload>({
      query: (payload) => ({ url: `/password/update`, method: 'post', data: payload }),
    }),
    inviteUser: builder.mutation<void, InviteUserPayload>({
      query: (payload) => ({ url: `/users`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Users'] : []),
    }),
    deactivateUser: builder.mutation<void, number>({
      query: (userId) => ({ url: `/users/${userId}`, method: 'delete' }),
      invalidatesTags: (result) => (result ? ['Users'] : []),
    }),
    getOutcomes: builder.query<OutcomesResponse, void>({
      query: () => ({ url: `/outcomes`, method: 'get' }),
    }),
    /*Stripe Calls*/
    getStripeIntent: builder.query<any, void>({
      query: () => ({ url: `/customers/get_intent`, method: 'get' }),
      providesTags: (result) => (result ? ['Stripe', 'Customers'] : []),
    }),
    addStripeCard: builder.mutation<any, any>({
      query: (payload) => ({ url: `/customers/add-card`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Stripe', 'Customers'] : []),
    }),
    /*Cronofy calls*/
    getCronofyUserCalendar: builder.query<CronofyCalendarsResponse, void>({
      query: () => ({ url: '/cronofy/calendars', method: 'get' }),
      providesTags: (result) => (result ? ['Cronofy'] : []),
    }),
    updateCronofyUserCalendar: builder.mutation<any | string, CronofyUserCalendarMutationPayload>({
      query: (payload) => ({ url: `/cronofy/calendar`, method: 'patch', data: payload }),
      invalidatesTags: (result) => (result ? ['CronofyUser'] : []),
    }),
    getCronofyUserInfo: builder.query<any, void>({
      query: () => ({ url: '/cronofy/userinfo?data_center_url=api.cronofy.com', method: 'get' }),
      providesTags: (result) => (result ? ['Cronofy'] : []),
    }),
    disconnectCronofy: builder.mutation<any, void>({
      query: () => ({ url: '/cronofy/disconnect', method: 'delete' }),
      invalidatesTags: (result) => (result ? ['Cronofy', 'CronofyUser'] : []),
    }),
    createCronofyEvent: builder.mutation<any, any>({
      query: (payload) => ({ url: '/cronofy/availability-rule', method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Cronofy'] : []),
    }),
    getCronofyAvailabilityRule: builder.query<any, string>({
      query: (ruleId) => ({ url: '/cronofy/availability-rules?rule_id=' + ruleId, method: 'get' }),
      providesTags: (result) => (result ? ['Cronofy'] : []),
    }),
    getCronofyUser: builder.query<any, void>({
      query: () => ({ url: '/cronofy/user', method: 'get' }),
      providesTags: (result) => (result ? ['CronofyUser'] : []),
    }),
    postCronofyConferencingSettings: builder.mutation<
      CronofyConferencingSettingsResponse,
      CronofyConferencingSettingsPayload
    >({
      query: (payload) => ({ url: `/cronofy/conferencing-settings`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Cronofy', 'CronofyUser'] : []),
    }),
    getFeedbackByRole: builder.query<any, any>({
      query: ({ roleId, per_page = 10, page = 1 }) => ({
        url: `/roles/${roleId}/feedback`,
        method: 'get',
        params: {
          ['per-page']: per_page,
          page,
        },
      }),
      providesTags: (result) => (result ? ['Feedback'] : []),
    }),
    getCandidateReviewedMessage: builder.query<any, void>({
      query: () => ({ url: '/reviewed-messages', method: 'get' }),
    }),
    getCompanyLocations: builder.query<any, any>({
      query: ( {page = 1, per_page=100} ) => ({ url: '/customers/locations', method: 'get', params: {page, per_page} }),
      providesTags: ['Locations'],
    }),
    postCompanyLocation: builder.mutation<any, any>({
      query: ( payload ) => ({ url: '/customers/locations', method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? [{ type: 'Locations', id: result.id }] : []),
    }),
    deleteCompanyLocation: builder.mutation<any, any>({
      query: ( payload ) => ({ url: '/customers/locations', method: 'delete', data: payload }),
      invalidatesTags: (result) => (result ? [{ type: 'Locations', id: result.id }] : []),
    }),
    getModals: builder.query<any, void>({
      query: () => ({ url: '/modals', method: 'get' }),
      providesTags: ['Modals'],
    }),
    getTopMessages: builder.query<any, void>({
      query: () => ({ url: '/top-messages', method: 'get' }),
      providesTags: ['Messages'],
    }),
    getUserCreatedRoleSkills: builder.query<any, any>({
      query: (role_id) => ({ url: `/roles/${role_id}/ai-skills`, method: 'get' }),
      providesTags: ['AISkills'],
    }),
    requestUpgradeInfo: builder.mutation<any, any>({
      query: (data) => ({ url: '/customers/request-upgrade-info', method: 'post', data })
    }),
    getHistoryByRole: builder.query<any, any>({
      query: ({ roleId, per_page = 10, page = 1, types }) => ({
        url: `/roles/${roleId}/history`,
        method: 'get',
        params: {
          ['per-page']: per_page,
          page,
          types,
        },
      }),
    }),
    postRoleFeedback: builder.mutation<any, any>({
      query: ({ roleId, payload }) => ({ url: `/roles/${roleId}/feedback`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Feedback', 'Role'] : []),
    }),
    getCompanyContacts: builder.query<any, any>({
      query: ( {customer_id} ) => ({ url: `/customers/get-contacts?customer_id=${customer_id}`, method: 'get' }),
    }),
    validatePasswordResetToken: builder.mutation<any, any>({
      query: ({token, email}) => ({ url: '/password/reset/validateToken', method: 'post', data: {token, email} }),
    }),
    getTalentActivity: builder.query<any, any>({
      query: ({roleId, talentId}) => ({ url: `/roles/${roleId}/talent/${talentId}/activity`, method: 'get' }),
      providesTags: (result) => (result ? [{ type: 'TalentActivity' as const, id: result.talent_id }] : [])
    }),
    getCompanyRoles: builder.query<any, any>({
      query: ({ companyId }) => ({
        url: `/customers/${companyId}/roles`,
        method: 'get'
      })
    }),
    addUserToRoles: builder.mutation<any, any>({
      query: ({user_id, roles}) => ({ url: '/roles/user', method: 'post', data: {user_id, roles} }),
      invalidatesTags: (result) => (result ? ['Users'] : []),
    }),
    getATSRoles: builder.query<ListResponse<IRole>, RoleParams>({
      query: ({ type, page = 1, search = '', per_page = 10, ...rest }) => ({
        url: '/roles',
        method: 'get',
        params: {
          'role-filter-type': type,
          search,
          page,
          per_page,
          ...rest,
        },
      }),
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'ATSRoles' as const, id })), { type: 'ATSRoles', id: 'PARTIAL-LIST' }]
          : [{ type: 'ATSRoles', id: 'PARTIAL-LIST' }],
    }),
    getSearchLocations: builder.query<any, any>({
      query: ({search = ''}) => ({
        url: '/locations',
        method: 'get',
        params: {
          'search-term': search
        }
      })
    }),
    getPricingPlans: builder.query<any, void>({
      query: () => ({ url: '/pricing-plans', method: 'get' }),
      providesTags: ['PricingPlans'],
    }),
    postCustomerPricinglan: builder.mutation<any, any>({
      query: ({ payload }) => ({ url: `/customer-pricing-plan`, method: 'post', data: payload })
    }),
    getCustomerPricingPlan: builder.query<any, any>({
      query: ({ token }) => ({ url: `/customer-pricing-plan/${token}`, method: 'get' })
    }),
    postCustomerCompleteSignup: builder.mutation<any, any>({
      query: ({ token, payload }) => ({ url: `/customer-pricing-plan/${token}/complete-signup`, method: 'post', data: payload })
    }),
    postConctactSales: builder.mutation<any, any>({
      query: ({ payload }) => ({ url: `/customer-pricing-plan/contact-sales`, method: 'post', data: payload })
    }),
    postRequestUpgradePlan: builder.mutation<any, any>({
      query: ({ payload }) => ({ url: `/customer-pricing-plan/upgrade-plan`, method: 'post', data: payload })
    }),
    postCancelPlanRenewal: builder.mutation<any, any>({
      query: ({ payload }) => ({ url: `/customer-pricing-plan/cancel-plan`, method: 'post', data: payload }),
      invalidatesTags: (result) => (result ? ['Customers'] : []),
    }),
    postReactivatePlan: builder.mutation<any, any>({
      query: ({ payload }) => ({ url: `/customer-pricing-plan/reactivate-plan`, method: 'post', data: payload })
    }),
    postMfaStatus: builder.mutation<any, {mfa_enabled: boolean, customer_id: number}>({
      query: ({ mfa_enabled, customer_id }) => ({ url: `customers/mfa-status`, method: 'post', data: {mfa_enabled, customer_id} }),
      invalidatesTags: ['Customers'],
    }),
    postVerifyOTP: builder.mutation<any, {code: string, token: string}>({
      query: ({ code, token }) => ({ url: `/verify-otp`, method: 'post', data: {code, token} })
    }),
    postResendOTP: builder.mutation<any, {token: string}>({
      query: ({ token }) => ({ url: `/resend-otp`, method: 'post', data: {token} })
    }),
    postRemoveSuggestion: builder.mutation<any, any>({
      query: ({ roleId, payload }) => ({ url: `/roles/${roleId}/remove-suggestion`, method: 'post', data: payload })
    }),
    getReactivationSetupIntent: builder.query<any, any>({
      query: ({ customerId }) => ({ url: `/customer-pricing-plan/${customerId}/reactivation-setup-intent`, method: 'get' })
    }),
    postCompletePlanReactivation: builder.mutation<any, any>({
      query: ({ customerId, payload }) => ({ url: `/customer-pricing-plan/${customerId}/reactivation-payment-intent/complete`, method: 'post', data: payload }),
      invalidatesTags: ['PricingPlans', 'Customers'],
    }),
  }),
});

export const {
  useLazyCsrfQuery,
  useSignUpMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetRolesQuery,
  useGetRoleMetricsQuery,
  usePatchUserMutation,
  useSignInMutation,
  useSignOutMutation,
  useGetRoleByIdQuery,
  useGetRoleLastReviewedByQuery,
  useGetRoleTalentQuery,
  useGetRoleStatsQuery,
  useGetRoleTalentPaginatedQuery,
  useLazyGetRoleTalentQuery,
  usePatchTalentMutation,
  usePostRoleInfoMutation,
  usePatchRoleInfoMutation,
  usePostContactSupportMutation,
  useDeleteRoleUserMutation,
  usePostRoleUserMutation,
  useCreateRoleMutation,
  useRequestUpgradeInfoMutation,
  usePauseRoleMutation,
  useResumeRoleMutation,
  useCloseRoleMutation,
  useOpenRoleMutation,
  useRoleUsersMutation,
  useGetCompanyQuery,
  useGetCompanyInformationQuery,
  useGetCompanyIntegrationsQuery,
  useConnectGreenhouseMutation,
  useDeleteGreenhouseMutation,
  useGetGreenhouseRolesQuery,
  useLazyGetGreenhouseRolesQuery,
  useCreateRoleFromGreenhouseMutation,
  useLinkGreenhouseJobMutation,
  useUnlinkGreenhouseJobMutation,
  useConnectOktaMutation,
  useDisconnectOktaMutation,
  useConnectLeverMutation,
  useDisconnectLeverMutation,
  useConnectSapMutation,
  useDisconnectSapMutation,
  useGetSapRolesQuery,
  useLazyGetSapRolesQuery,
  useCreateRoleFromSapMutation,
  useLinkSapJobMutation,
  useUnlinkSapJobMutation,
  useGetICIMSQuery,
  useConnectICIMSMutation,
  useDisconnectICIMSMutation,
  useGetICIMSRolesQuery,
  useLazyGetICIMSRolesQuery,
  useUpdateICIMSPortalsMutation,
  useLazyGetICIMSPortalsQuery,
  useGetICIMSRegionsQuery,
  useCreateRoleFromICIMSMutation,
  useLinkICIMSJobMutation,
  useUnlinkICIMSJobMutation,
  useGetLeverRolesQuery,
  useLazyGetLeverRolesQuery,
  useCreateRoleFromLeverMutation,
  useLinkLeverJobMutation,
  useUnlinkLeverJobMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useUpdatePasswordMutation,
  useGetCompanyUsersQuery,
  useGetStripeIntentQuery,
  useAddStripeCardMutation,
  useGetUsersQuery,
  usePatchCompanyUserMutation,
  useInviteUserMutation,
  useDeactivateUserMutation,
  useGetOutcomesQuery,
  /*Cronofy hooks*/
  useCreateCronofyEventMutation,
  useGetCronofyUserInfoQuery,
  useLazyGetCronofyUserCalendarQuery,
  useGetCronofyUserCalendarQuery,
  useDisconnectCronofyMutation,
  useGetCronofyAvailabilityRuleQuery,
  useLazyGetCronofyAvailabilityRuleQuery,
  useGetCronofyUserQuery,
  useLazyGetCronofyUserQuery,
  useUpdateCronofyUserCalendarMutation,
  usePostCronofyConferencingSettingsMutation,
  useGetFeedbackByRoleQuery,
  useGetCandidateReviewedMessageQuery,
  useGetCompanyLocationsQuery,
  usePostCompanyLocationMutation,
  useDeleteCompanyLocationMutation,
  useGetModalsQuery,
  useGetTopMessagesQuery,
  useLazyGetUserCreatedRoleSkillsQuery,
  useGetUserCreatedRoleSkillsQuery,
  useGetHistoryByRoleQuery,
  usePostRoleFeedbackMutation,
  useGetCompanyContactsQuery,
  useValidatePasswordResetTokenMutation,
  useGetTalentActivityQuery,
  useGetCompanyRolesQuery,
  useAddUserToRolesMutation,
  useGetATSRolesQuery,
  useReactivateUserMutation,
  useGetSearchLocationsQuery,
  useGetPricingPlansQuery,
  usePostCustomerPricinglanMutation,
  useGetCustomerPricingPlanQuery,
  usePostCustomerCompleteSignupMutation,
  usePostConctactSalesMutation,
  usePostRequestUpgradePlanMutation,
  usePostCancelPlanRenewalMutation,
  usePostReactivatePlanMutation,
  usePostMfaStatusMutation,
  usePostVerifyOTPMutation,
  usePostResendOTPMutation,
  usePostRemoveSuggestionMutation,
  useLazyGetReactivationSetupIntentQuery,
  usePostCompletePlanReactivationMutation,
} = rolebotApi;
